.login {
  &__root {
    height: 100vh;
    background-image: url("../../assets/background-blur.jpg");
    background-size: cover;
    h2, p {
      text-align: center;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }

}
@base-color: #2153f4;