.column-header {
    font-size: 16px !important;
    color: black;
    background-color: red;
}

.cell-value {
    font-size: 18 !important;
}

.pl-25 {
    padding-left: 25px;
}

.button-group {
    display: flex;
    justify-content: flex-end;
}

.flex-box-grid-item-modal {
    display: flex;
    flex-direction: row;
}

.rs-divider-horizontal {
    height: 2px !important;
    margin: 0 0 20px 0 !important;
}

.input-group {
    width: 250px !important;
    margin-bottom: 10px !important;
}

h6 {
    margin-bottom: 20px !important;
}

.rs-input-group-btn {
    background-color: #1475cae0 !important;
}

.rs-input-group-btn .rs-icon-search {
    color: aliceblue;    
}

.rs-picker-toggle-value {
    color: black !important;
}

.rs-btn-primary {
    background-color: #1475cae0 !important;
}

.input-with-underline {
    border-bottom: 1px solid #e5e5ea !important;
    border-width: 0 0 2.5px 0 !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
}

.first {
    margin-top: -33px;
}

.rs-modal-dialog {
    width: 1000px;
}

.input-number {
    align-self: center;
    margin-bottom: -1px !important;
}

.rs-modal-footer {
    margin-top: 40px;
    display: flex;
    padding: 0 20px;
    /* text-align: left; */
}

.btn-ok {
    display: flex !important;
    justify-content: flex-start;
}

.btn-cancel {
    display: flex !important;
    justify-content: flex-end;
}

.btn-group-modal {
    width: 100px;
}

.rs-modal-body {
    width: 100% !important;
    height: 100% !important;
    overflow-y: scroll !important;
    padding-right: 17px !important; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box !important; /* So the width will be 100% + 17px */
}

.rs-modal-content {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
}

.custom-input-number .input {
      text-align: center;
}

.custom-input-number .rs-input-number-btn-group-vertical {
      display: none;
}

.input-role {
   margin: -7px 0 0 -12px !important;
   border: 0 !important;
   padding: 0 11px 7px 11px !important;
}

.underline {
    border-bottom: 1px solid #e5e5ea !important;
    border-width: 0 0 2.5px 0 !important;
    margin-bottom: 7px !important;
}

.rs-auto-complete-menu {
    width: 230px !important;
    /* width: 100% !important; */
    height: 200px !important;
    overflow-y: scroll !important;
    /* padding-right: 17px !important;  */
    /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box !important; /* So the width will be 100% + 17px */
}

.rs-picker-tree-menu {
    max-width: 250px;
    max-height: 300px;
}

.rs-picker-menu {
    max-width: 220px;
    max-height: 300px;
}

.rs-picker-date-menu {
    max-width: 280px;
    max-height: 326px;
}

.mt-20 {
    margin-top: 20px !important;
}