.rs-table-row-header {
    border-bottom: 2px solid #8e8e93 !important;
}

.rs-table-cell-content {
    color: black;
}

.rs-table {
    border: 2px solid #8e8e93 !important;
    padding: 0 !important;
}

.contacts {
    display: flex !important;
    flex-direction: row !important;
}

.contacts-label {
    display: flex !important;
    align-self: center !important;
    margin-bottom: 14px !important;
    padding-left: 20px;
}

.input-group-cnt {
    margin-left: 3px !important;
    width: 250px !important;
    margin-bottom: 10px !important;
}

.auto-complete-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 35px;
}

.cnt-auto-complete {
    display: flex;
    justify-content: flex-start;
}

.cnt-add {
    display: flex !important;
    justify-content: flex-end;
    padding-right: 10px;
}

.contact-form-group {
    display: flex;
    flex-direction: row;
}

.footer-contacts-label {
    display: flex !important;
    align-self: center !important;
    margin-bottom: 14px !important;
    padding-right: 5px;
}

.rs-modal-footer {
    text-align: left !important;
}

.label-roll {
    display: flex !important;
    align-self: flex-end !important;
    margin-bottom: 7px !important;
    margin-right: 4px;
}